@font-face {
    font-family: 'SVN-Gilroy'; /*a name to be used later*/
    src: url('../src/res/fonts/SVN-Gilroy\ Regular.otf'); /*URL to font*/
}

body {
    background-color: #f3f3f3;
    margin: 0;
    font-family: 'SVN-Gilroy';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.auth_layout {
    background: url(../src/res/images/background.svg) no-repeat center center
        fixed;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-table-thead > tr > th.ant-table-cell {
    background-color: #f2f6f8;
    font-weight: bold;
}

.ant-breadcrumb-link {
    font-size: 12px;
}

td > div > img.ant-image-img {
    object-fit: cover;
}
li.ant-pagination-total-text {
    color: #aab0c7;
    font-size: 14px;
}
.ant-pagination.mini .ant-pagination-item {
    min-width: 33px;
    height: 33px;
    margin: 0;
    border-radius: 4px;
    line-height: 33px;
}
.ant-modal-footer {
    padding: 5px 16px 20px 16px;
    text-align: left;
    background: transparent;
    border-top: none;
    border-radius: 0 0 2px 2px;
}
.buttonAddNew {
    height: 40px;
    border-radius: 3px;
}
.image-customer img.ant-image-img {
    border-radius: 6px;
    object-fit: contain;
    border: 1px solid #e7e7e7;
}
.image-search {
    width: 17px;
    object-fit: cover;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0 11px;
    border-radius: 3px;
}
.lable-price .ant-form-item-label > label {
    height: 40px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}
.avatar-news .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 210px;
    border-radius: 6px;
}
.avatar-news .ant-upload.ant-upload-select-picture-card img {
    max-width: 100%;
    max-height: 100%;
}
.avatar-banner .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
    min-height: 141px;
    border-radius: 6px;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
}
.avatar-banner .ant-upload.ant-upload-select-picture-card img {
    max-width: 100%;
    max-height: 100%;
}
.ck-editor__editable_inline {
    min-height: 450px;
}
.example-spin {
    text-align: center;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
}
.input-phone-staff .react-tel-input .form-control {
    width: 100% !important;
    border-radius: 3px;
    height: 40px;
    border: 1px solid #d9d9d9;
}
.input-phone-staff .react-tel-input .selected-flag {
    width: 40px;
}
.sufix-number {
    background: #e5edf1;
    border-radius: 3px;
}
.label-range-picker .ant-form-item-label > label {
    padding-top: 5px;
}

.titlePolicy::after {
    content: '';
    display: block;
    background: #5473ff;
    width: 50px;
    height: 3px;
    position: relative;
    margin-bottom: 10px;
    top: 5px;
}
.falsh-deal .ant-tag-checkable-checked {
    background: white;
    color: #5473ff;
}
.falsh-deal .checkedBox {
    border: 1px solid #5473ff !important;
    position: relative;
}
.falsh-deal .image-flash {
    position: absolute;
    top: 0;
    right: 0;
}
.falsh-deal .ant-tag-checkable {
    cursor: pointer;
    padding: 0;
}
.falsh-deal .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #5473ff;
    background: white;
}
.falsh-deal .ant-tag-checkable:active {
    background-color: white;
    color: #5473ff;
}
.flash-image-product .ant-image {
    width: 100%;
}
.checkbox-right .ant-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
}
.flash-image-product .ant-image .ant-image-img {
    height: 173px;
    object-fit: contain;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: unset !important;
}
.Calendar__monthYear.-shown {
    width: fit-content;
    display: inline-flex;
    justify-content: center;
}
.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
    background: #11a1fd !important;
}
.image-gallery-product .image-gallery-left-nav .image-gallery-svg,
.image-gallery-product .image-gallery-right-nav .image-gallery-svg {
    height: 45px !important;
    width: 30px !important;
}
.image-gallery-product .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 70px;
    object-fit: cover;
}
.image-gallery-product .image-gallery-content {
    top: 10px !important;
}
.image-gallery-product .image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.image-gallery-product .fullscreen .image-gallery-slide img {
    max-height: 100vh;
}
.image-gallery-product
    .image-gallery-content
    .image-gallery-slide
    .image-gallery-image {
    height: 360px;
}

.lableRequired {
    text-transform: uppercase;
    margin-bottom: 7px;
}
.lableRequired::after {
    display: inline-block;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    padding-left: 3px;
}
